<template>
  <div>
    <h3 class="page-title">دەرس باشقۇرۇش</h3>
    <el-divider></el-divider>
    <!-- list -->
    <div v-if="showList">
      <el-row class="rtl-right">
        <el-button type="primary" plain icon="el-icon-circle-plus-outline" v-if="pageTags.create" @click="add"
          >قوشۇش
        </el-button>
      </el-row>

      <div class="rtl-right search-box">
        <el-row :gutter="10">
          <el-col :span="5">
            <el-select
              v-model="filterForm.teacher_id"
              class="rtl-right"
              @change="getList()"
              clearable
              placeholder="ئوقۇتقۇچى"
            >
              <el-option v-for="item in teachersData" :key="item.id" :label="item.name_ug" :value="item.id">
              </el-option>
            </el-select>
          </el-col>
          <el-col :span="5">
            <el-select
              v-model="filterForm.course_category_id"
              class="rtl-right"
              @change="getList()"
              clearable
              placeholder="ئاساسىي تىزىملىك"
            >
              <el-option v-for="item in bigCategoryData" :key="item.id" :label="item.name_ug" :value="item.id">
              </el-option>
            </el-select>
          </el-col>
          <el-col :span="5">
            <el-input
              v-model="filterForm.keyword"
              @keyup.native.enter="toSearch()"
              clearable
              placeholder="ھالقىلىق سۆزنى كىرگۈزۈڭ"
            ></el-input>
          </el-col>
          <el-col :span="4">
            <el-button type="success" plain icon="el-icon-search" @click="toSearch()">ئىزدەش </el-button>
            <el-button type="danger" plain icon="el-icon-circle-close" @click="toClear()">بىكار قىلىش </el-button>
          </el-col>
        </el-row>
      </div>

      <div class="table-box" v-if="pageTags.list">
        <el-table :data="tableData" border style="width: 100%" v-loading="loading">
          <!-- <el-table-column type="expand">
            <template slot-scope="props">
              <el-table :data="props.row.prices" border style="width: 100%">
                <el-table-column prop="term.name_ug" label="مۇددەت"></el-table-column>
                <el-table-column prop="term.days" label="كۈن"></el-table-column>
                <el-table-column prop="price" label="باھا"></el-table-column>
              </el-table>
              <el-divider></el-divider>
              <p class="mytitle mytitle-ug">چۈشەندۈرلىشى  (ئۇيغۇرچە):</p>
              <div v-html="props.row.description_ug" class="mycontent mycontent-ug"></div>
              <el-divider></el-divider>
              <p class="mytitle mytitle-zh">描述（中文）:</p>
              <div v-html="props.row.description_zh" class="mycontent"></div>
            </template>
          </el-table-column> -->
          <el-table-column prop="order" label="تەرتىپى" width="80"></el-table-column>
          <el-table-column prop="image" label="رەسىم" width="150">
            <template slot-scope="scope">
              <img :src="scope.row.image" class="table-img" />
            </template>
          </el-table-column>
          <el-table-column prop="name_ug" label="دەرس (ئۇيغۇرچە)"></el-table-column>
          <el-table-column prop="price" label="باھاسى" width="100"></el-table-column>
          <!-- <el-table-column prop="name_zh" label="دەرس (خەنزۇچە)"></el-table-column> -->
          <el-table-column prop="teacher.name_ug" label="ئوقۇتقۇچى"></el-table-column>
          <el-table-column prop="category.name_ug" label="ئاساسىي تىزىملىك"></el-table-column>
          <el-table-column prop="recommend" width="120" label="تەۋسىيە">
            <template slot-scope="scope">
              <label v-show="scope.row.recommend == 1">ھەئە</label>
              <label v-show="scope.row.recommend == 0">ياق</label>
            </template>
          </el-table-column>
          <el-table-column prop="status" width="120" label="ھالەت">
            <template slot-scope="scope">
              <label v-show="scope.row.status == 1">ئوچۇق</label>
              <label v-show="scope.row.status == 0">ئېتىك</label>
              <label v-show="scope.row.status == 2">سېتىلىپ بولدى</label>
            </template>
          </el-table-column>
          <el-table-column prop="id" label="ID" width="80"></el-table-column>
          <el-table-column label="مەشغۇلات" prop="dosome" width="370">
            <template slot-scope="scope">
              <el-button size="mini" type="primary" v-if="pageTags.update" @click="handleEdit(scope.$index, scope.row)"
                >تەھرىرلەش
              </el-button>
              <el-button size="mini" type="danger" v-if="pageTags.delete" @click="handleDelete(scope.$index, scope.row)"
                >ئۆچۈرۈش
              </el-button>
              <el-button size="mini" type="success" @click="handleContent(scope.row)">مۇندەرىجە</el-button>
              <el-button size="mini" type="warning" @click="handleComment(scope.row)">ئىنكاس</el-button>
            </template>
          </el-table-column>
        </el-table>
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          background
          :current-page="current_page"
          :page-sizes="[10, 20, 30, 40, 50, 100]"
          layout="sizes,prev, pager, next,total,jumper"
          :total="total"
        >
        </el-pagination>
      </div>
    </div>

    <!-- content -->
    <div v-if="showContent">
      <el-row class="rtl-right">
        <el-button type="primary" plain icon="el-icon-right" @click="backToList">قايتىش</el-button>
        <el-button type="primary" plain icon="el-icon-circle-plus-outline" @click="addContent"
          >مۇندەرىجە قوشۇش</el-button
        >
        <el-button type="warning" plain icon="el-icon-money" @click="setContentFree"
          >مۇندەرىجە ھەقلىق ھالىتىنى تەڭشەش</el-button
        >
      </el-row>
      <el-divider>مۇندەرىجە ({{ curCourse.name_ug }})</el-divider>
      <el-table
        :data="contentTableData"
        border
        style="width: 100%"
        @selection-change="handleSelectionChange"
        v-loading="contentLoading"
      >
        <el-table-column type="selection" width="55"></el-table-column>
        <el-table-column prop="name_ug" label="نامى (ئۇيغۇرچە)"></el-table-column>
        <el-table-column prop="name_zh" label="نامى (خەنزۇچە)"></el-table-column>
        <el-table-column prop="source_url" label="ئادرېس"></el-table-column>
        <el-table-column prop="order" label="تەرتىپى"></el-table-column>
        <el-table-column prop="free" width="120" label="ھەقسىزمۇ">
          <template slot-scope="scope">
            <label v-show="scope.row.free == 1">ھەئە</label>
            <label v-show="scope.row.free == 0">ياق</label>
          </template>
        </el-table-column>
        <el-table-column prop="status" width="120" label="ھالەت">
          <template slot-scope="scope">
            <label v-show="scope.row.status == 1">ئوچۇق</label>
            <label v-show="scope.row.status == 0">ئېتىك</label>
          </template>
        </el-table-column>
        <el-table-column label="مەشغۇلات" prop="dosome" width="370">
          <template slot-scope="scope">
            <el-button size="mini" type="primary" @click="handleEditContent(scope.$index, scope.row)"
              >تەھرىرلەش
            </el-button>
            <el-button size="mini" type="danger" @click="handleDeleteContent(scope.$index, scope.row)"
              >ئۆچۈرۈش
            </el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>

    <!-- comment -->
    <div v-if="showComment">
      <el-row class="rtl-right">
        <el-button type="primary" plain icon="el-icon-right" @click="backToList">قايتىش</el-button>
      </el-row>
      <el-divider>ئىنكاس ({{ curCourse.name_ug }})</el-divider>
      <el-table :data="commentTableData" border style="width: 100%" v-loading="loading">
        <el-table-column prop="user.name" width="200" label="ئىسمى"></el-table-column>
        <el-table-column prop="user.phone" width="200" label="تېلېفون"></el-table-column>
        <el-table-column prop="content" label="ئىنكاس"></el-table-column>
        <el-table-column prop="status" width="100" label="ھالەت">
          <template slot-scope="scope">
            <label v-show="scope.row.status == 1" style="color:#139d59;">تەستىقتىن ئۆتتى</label>
            <label v-show="scope.row.status == 0" style="color:#ff5151;">تەستىقتىن ئۆتمىدى</label>
          </template>
        </el-table-column>
        <el-table-column prop="created_at" width="200" label="قۇرۇلغان ۋاقىت"></el-table-column>
        <el-table-column label="مەشغۇلات" prop="dosome" width="200">
          <template slot-scope="scope">
            <el-button size="mini" type="primary" @click="handleEditComment(scope.$index, scope.row)"
              >تەھرىرلەش
            </el-button>
            <el-button size="mini" type="danger" @click="handleDeleteComment(scope.$index, scope.row)"
              >ئۆچۈرۈش
            </el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>

    <!-- el-dialog -->
    <el-dialog :title="dialogTitle" :visible.sync="dialogVisible" width="75%" top="1vh" :before-close="handleClose">
      <div class="rtl-right form-content">
        <el-form label-width="120px" :model="ruleForm" ref="ruleForm">
          <el-form-item label="دەرس (ئۇيغۇرچە)" prop="name_ug">
            <el-input v-model="ruleForm.name_ug" placeholder="دەرس (ئۇيغۇرچە)"></el-input>
          </el-form-item>
          <el-form-item label="دەرس (خەنزۇچە)" prop="name_zh">
            <el-input v-model="ruleForm.name_zh" class="ltr-left" placeholder="名称（中文）"></el-input>
          </el-form-item>
          <el-row :gutter="15" style="margin-bottom:22px;">
            <el-col :span="12">
              <el-form-item label="رەسىم" prop="image">
                <el-upload
                  class="avatar-uploader"
                  :action="imgActionUrl"
                  name="image"
                  :data="imageData"
                  :headers="imageHeader"
                  :on-error="handleImageError"
                  :on-success="handleImageSuccess"
                >
                  <img v-if="ruleForm.image" :src="ruleForm.image" class="avatar" />
                  <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                </el-upload>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="ئاساسىي تىزىملىك" prop="big_category_id">
                <el-select
                  v-model="ruleForm.big_category_id"
                  class="rtl-right"
                  filterable
                  @change="bigCateChange"
                  placeholder="ئاساسىي تىزىملىك"
                >
                  <el-option v-for="item in bigCategoryData" :key="item.id" :label="item.name_ug" :value="item.id">
                  </el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="تارماق تىزىملىك" prop="sub_category_id">
                <el-select
                  v-model="ruleForm.sub_category_id"
                  class="rtl-right"
                  filterable
                  placeholder="تارماق تىزىملىك"
                >
                  <el-option v-for="item in subCategoryData" :key="item.id" :label="item.name_ug" :value="item.id">
                  </el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="ئوقۇتقۇچى" prop="teacher_id">
                <el-select v-model="ruleForm.teacher_id" class="rtl-right" filterable placeholder="ئوقۇتقۇچى">
                  <el-option v-for="item in teachersData" :key="item.id" :label="item.name_ug" :value="item.id">
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
          <!-- <el-row :gutter="15" style="margin-bottom:22px;"> -->
          <!-- <el-col :span="12">
              <el-form-item label="ۋقتى ئۆتۈش چىسلاسى" prop="expired_at">
                <el-date-picker v-model="ruleForm.expired_at" value-format="yyyy-MM-dd HH:mm:ss" type="datetime" placeholder="ۋقتى ئۆتۈش چىسلاسى"></el-date-picker>
              </el-form-item>
            </el-col> -->
          <!-- </el-row> -->
          <el-row :gutter="15" style="margin-bottom: 10px;">
            <el-col :span="8">
              <el-form-item label="تەۋسىيە" prop="recommend" class="radio-form">
                <el-radio v-model="ruleForm.recommend" label="1">ھەئە</el-radio>
                <el-radio v-model="ruleForm.recommend" label="0">ياق</el-radio>
              </el-form-item>
            </el-col>
            <el-col :span="10">
              <el-form-item label="ھالەت" prop="status" class="radio-form">
                <el-radio v-model="ruleForm.status" label="1">ئوچۇق</el-radio>
                <el-radio v-model="ruleForm.status" label="0">ئېتىك</el-radio>
                <el-radio v-model="ruleForm.status" label="2">سېتىلىپ بولدى</el-radio>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="تەرتىپى" prop="order">
                <el-input type="number" v-model="ruleForm.order" class="ltr-left" placeholder="تەرتىپى"></el-input>
              </el-form-item>
            </el-col>
          </el-row>

          <!-- term-price -->
          <div class="main-box">
            <el-form-item label="مۇددەت باھاسى" prop="price">
              <el-row :gutter="15" v-for="(one, index) in termPriceList" :key="index" class="price-box">
                <el-col :span="10">
                  <el-select v-model="one.term_id" class="rtl-right" filterable placeholder="مۇددەت  تاللاڭ ">
                    <el-option
                      v-for="item in termsData"
                      :key="item.id"
                      :label="item.name_ug + '---' + item.days + ' كۈن  '"
                      :value="item.id"
                    >
                    </el-option>
                  </el-select>
                </el-col>
                <el-col :span="10">
                  <el-input type="number" v-model="one.price" class="ltr-left" placeholder="باھاسى"></el-input>
                </el-col>
                <!-- <el-col :span="2" class="last-col">
                  <i class="el-icon-error" @click="removeItem(termPriceList,index)"></i>
                </el-col> -->
              </el-row>
            </el-form-item>
            <!-- <el-row>
              <el-col :span="10" :offset="7">
                <el-button icon="el-icon-circle-plus-outline" class="add-icon" @click="addItem(termPriceList)">يەنە بىرنى قوشۇش</el-button>
              </el-col>
            </el-row> -->
          </div>

          <el-form-item label="ئىزاھات (ئۇيغۇرچە)" prop="description_ug">
            <!-- <el-input type="textarea" v-model="ruleForm.description_ug" placeholder="ئىزاھات (ئۇيغۇرچە)"></el-input> -->
            <vue-ueditor-wrap v-model="ruleForm.description_ug" :config="myConfig"></vue-ueditor-wrap>
          </el-form-item>
          <el-form-item label="ئىزاھات (خەنزۇچە)" prop="description_zh">
            <!-- <el-input type="textarea" v-model="ruleForm.description_zh" class="ltr-left" placeholder="描述（中文）"></el-input> -->
            <vue-ueditor-wrap v-model="ruleForm.description_zh" :config="myConfig"></vue-ueditor-wrap>
          </el-form-item>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button type="success" plain @click="submitForm('ruleForm')">مۇقىملاش</el-button>
        <el-button type="warning" plain @click="resetForm('ruleForm')">ئەسلىگە قايتۇرۇش</el-button>
        <el-button type="danger" plain @click="closeDialog('ruleForm')">بىكار قىلىش</el-button>
      </span>
    </el-dialog>

    <!-- edit comment el-dialog -->
    <el-dialog title="ئىنكاس ھالەت ئۆزگەرتىش" :visible.sync="dialogVisibleEditComment" width="35%">
      <div class="rtl-right form-content">
        <el-form label-width="120px">
          <el-form-item label="ھالەت" class="radio-form">
            <el-radio v-model="commentStatus" label="1">تەستىقتىن ئۆتتى</el-radio>
            <el-radio v-model="commentStatus" label="0">تەستىقتىن ئۆتمىدى</el-radio>
          </el-form-item>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button type="success" plain @click="submitCommentForm">مۇقىملاش</el-button>
        <el-button type="danger" plain @click="dialogVisibleEditComment = false">بىكار قىلىش</el-button>
      </span>
    </el-dialog>

    <!-- add and edit content dialog -->
    <el-dialog
      :title="dialogTitleContent"
      :visible.sync="dialogVisibleContent"
      width="65%"
      top="3vh"
      :before-close="handleCloseContent"
    >
      <div class="rtl-right form-content">
        <el-form label-width="120px" :model="ruleFormContent" ref="ruleFormContent">
          <el-form-item label="دەرس شەكلى">
            <el-select v-model="ruleFormContent.course_type_id" class="rtl-right" filterable placeholder="دەرس شەكلى">
              <el-option v-for="item in courseTypeData" :key="item.id" :label="item.name_ug" :value="item.id">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="مۇندەرىجە (ئۇيغۇرچە)" prop="name_ug">
            <el-input v-model="ruleFormContent.name_ug" placeholder="مۇندەرىجە (ئۇيغۇرچە)"></el-input>
          </el-form-item>
          <el-form-item label="مۇندەرىجە (خەنزۇچە)" prop="name_zh">
            <el-input v-model="ruleFormContent.name_zh" class="ltr-left" placeholder="目录（中文）"></el-input>
          </el-form-item>
          <el-form-item v-if="ruleFormContent.course_type_id == 3" label="مەزمۇن (ئۇيغۇرچە)" prop="content_ug">
            <!-- <el-input type="textarea" v-model="ruleFormContent.content_ug" placeholder="مەزمۇن (ئۇيغۇرچە)"></el-input> -->
            <vue-ueditor-wrap v-model="ruleForm.content_ug" :config="myConfig"></vue-ueditor-wrap>
          </el-form-item>
          <el-form-item v-if="ruleFormContent.course_type_id == 3" label="مەزمۇن (خەنزۇچە)" prop="content_zh">
            <!-- <el-input type="textarea" v-model="ruleFormContent.content_zh" class="ltr-left" placeholder="内容（中文）"></el-input> -->
            <vue-ueditor-wrap v-model="ruleForm.content_zh" :config="myConfig"></vue-ueditor-wrap>
          </el-form-item>
          <el-form-item label="ئادرېس" prop="source_url" v-if="ruleFormContent.course_type_id != 3">
            <el-input v-model="ruleFormContent.source_url" class="ltr-left" placeholder="ئادرېس"></el-input>
          </el-form-item>
          <el-form-item label="سىناق قويۇش" v-if="ruleFormContent.course_type_id != 3 && ruleFormContent.source_url">
            <div v-if="ruleFormContent.course_type_id == 1 && ruleFormContent.source_url">
              <audio :src="ruleFormContent.source_url" controls="controls">
                Your browser does not support the audio tag.
              </audio>
            </div>
            <div v-if="ruleFormContent.course_type_id == 2 && ruleFormContent.source_url">
              <!-- <video width="320" height="240" controls="controls">
                <source src="ruleFormContent.source_url" type="video/mp4" />
                <source src="ruleFormContent.source_url" type="video/ogg" />
                <source src="ruleFormContent.source_url" type="video/webm" />
              Your browser does not support the video tag.
              </video> -->
              <video width="80%" controls="controls">
                <source :src="ruleFormContent.source_url" type="video/mp4" />
                <source :src="ruleFormContent.source_url" type="video/ogg" />
                <source :src="ruleFormContent.source_url" type="video/webm" />
                <object data="ruleFormContent.source_url" width="320" height="240">
                  <embed :src="ruleFormContent.source_url" width="320" height="240" />
                </object>
                Your browser does not support the video tag.
              </video>
            </div>
          </el-form-item>
          <el-row>
            <el-col :span="5">
              <el-form-item label="تەرتىپى" prop="order">
                <el-input v-model="ruleFormContent.order" class="ltr-left" placeholder="تەرتىپى"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="10">
              <el-form-item label="ھەقسىزمۇ" prop="free" class="radio-form">
                <el-radio v-model="ruleFormContent.free" label="1">ھەئە</el-radio>
                <el-radio v-model="ruleFormContent.free" label="0">ياق</el-radio>
              </el-form-item>
            </el-col>
            <el-col :span="9">
              <el-form-item label="ھالەت" prop="status" class="radio-form">
                <el-radio v-model="ruleFormContent.status" label="1">ئوچۇق</el-radio>
                <el-radio v-model="ruleFormContent.status" label="0">ئېتىك</el-radio>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button type="success" plain @click="submitFormConetnt('ruleFormContent', 'noclose')" v-if="addContentDialog"
          >مۇقىملاش ۋە داۋاملاشتۇرۇش</el-button
        >
        <el-button type="success" plain @click="submitFormConetnt('ruleFormContent', 'close')">مۇقىملاش</el-button>
        <el-button type="warning" plain @click="resetFormContent('ruleFormContent')">ئەسلىگە قايتۇرۇش</el-button>
        <el-button type="danger" plain @click="closeDialogContent('ruleFormContent')">بىكار قىلىش</el-button>
      </span>
    </el-dialog>

    <!-- multiple change content free state -->
    <el-dialog title="ھەقلىق ھالىتىنى ئۆزگەرتىش" :visible.sync="multiChangeVisible" width="30%">
      <div class="rtl-right form-content">
        <el-form label-width="120px">
          <el-form-item label="ھەقسىزمۇ" prop="free" class="radio-form">
            <el-radio v-model="multiFree" label="1">ھەئە</el-radio>
            <el-radio v-model="multiFree" label="0">ياق</el-radio>
          </el-form-item>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button type="success" plain @click="submitMultiChange">مۇقىملاش</el-button>
        <el-button type="danger" plain @click="multiChangeVisible = false">بىكار قىلىش</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<style lang="scss" scoped>
  .avatar-uploader {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
    width: 150px;
    height: 150px;
    img {
      width: 150px;
      height: 150px;
    }
  }
  .avatar-uploader .el-upload:hover {
    border-color: #409eff;
  }
  .avatar-uploader-icon {
    font-size: 24px;
    color: #8c939d;
    width: 150px;
    height: 150px;
    line-height: 150px;
    text-align: center;
  }
  .table-img {
    width: 80px;
    height: 80px;
    border: 1px solid #ebeef5;
  }
  .el-date-editor {
    width: 100%;
  }
  .mytitle {
    color: #909399;
    font-size: 14px;
    padding-bottom: 10px;
  }
  .mycontent {
    direction: initial;
    text-align: initial;
    text-align: justify;
    line-height: 1.5rem;
  }
  .mytitle-ug,
  .mycontent-ug {
    direction: rtl;
    text-align: right;
  }
  .mytitle-zh {
    direction: ltr;
    text-align: left;
  }
  .last-col {
    text-align: center;
    position: relative;
    top: 8px;
    .el-icon-error {
      color: #ff5151;
      font-size: 28px;
      cursor: pointer;
    }
  }
  .price-box {
    overflow: hidden;
    [class*="el-col-"] {
      float: right;
    }
  }
  .add-icon {
    width: 100%;
  }
  .main-box {
    border: 1px dotted #67c23a;
    margin-bottom: 10px;
    padding: 10px 0;
    border-radius: 5px;
  }
  .search-box {
    .el-select,
    .el-input,
    .el-date-editor {
      width: 100%;
    }
    [class*="el-col-"] {
      float: right;
    }
  }
  audio {
    width: 80%;
  }
</style>

<script>
  var self;

  import baseConfig from "../base.config.js";
  var home_url = baseConfig.baseUrl + "editor/controller.php";
  import VueUeditorWrap from "vue-ueditor-wrap"; // ES6 Module

  export default {
    activated: function() {
      self = this;
      self.getList();
      self.getTeachersList();
      self.getBigCategory();
      self.getCourseType();
      self.getTermsList();

      self.imgActionUrl = baseConfig.imgActionUrl;
    },
    data() {
      return {
        tableData: [],
        per_page: 10,
        current_page: 1,
        last_page: 0,
        total: 0,
        loading: false,
        dialogVisible: false,
        ruleForm: {
          image: "",
          big_category_id: "",
          sub_category_id: "",
          // expired_at:"",
          teacher_id: "",
          name_ug: "",
          name_zh: "",
          description_ug: "",
          description_zh: "",
          order: "",
          price: "",
          recommend: "1",
          status: "1",
        },
        editIndex: -1,
        dialogTitle: "دەرس باشقۇرۇش",
        addDialog: true,
        editId: "",
        pageTags: {
          create: true,
        },
        imgActionUrl: "",
        imageData: {
          folder: "test",
        },
        imageHeader: {
          Authorization: "Bearer " + localStorage.getItem("token"),
          "X-Requested-With": "XMLHttpRequest",
        },
        showList: true,
        showContent: false,
        showComment: false,
        curCourse: null,
        contentLoading: false,
        commentLoading: false,
        contentTableData: [],
        commentTableData: [],
        teachersData: [],
        bigCategoryData: [],
        subCategoryData: [],
        termsData: [],
        termPriceList: [
          {
            id: null,
            term_id: "",
            price: "",
          },
        ],
        dialogVisibleEditComment: false,
        commentStatus: "",
        curCommentId: "",
        dialogTitleContent: "",
        dialogVisibleContent: false,
        ruleFormContent: {
          course_id: "",
          course_type_id: "",
          name_ug: "",
          name_zh: "",
          content_ug: "",
          content_zh: "",
          source_url: "",
          order: "",
          free: "0",
          status: "1",
        },
        addContentDialog: true,
        courseTypeData: [],
        curContentId: "",
        multipleSelection: [],
        multiChangeVisible: false,
        multiFree: "0",
        filterForm: {
          teacher_id: "",
          course_category_id: "",
          keyword: "",
        },
        myConfig: {
          // 编辑器不自动被内容撑高
          autoHeightEnabled: false,
          // 初始容器高度
          initialFrameHeight: 500,
          // 初始容器宽度
          initialFrameWidth: "100%",
          // 上传文件接口（这个地址是我为了方便各位体验文件上传功能搭建的临时接口，请勿在生产环境使用！！！）
          // serverUrl: 'http://35.201.165.105:8000/controller.php',
          // serverUrl: 'https://durdana-api.hamdam.biz/editor/controller.php',
          serverUrl: home_url,
          // UEditor 资源文件的存放路径，如果你使用的是 vue-cli 生成的项目，通常不需要设置该选项，vue-ueditor-wrap 会自动处理常见的情况，如果需要特殊配置，参考下方的常见问题2
          UEDITOR_HOME_URL: "/UEditor/",
        },
      };
    },
    methods: {
      getList() {
        self.loading = true;
        self
          .$fetch("admin/course", {
            page: self.current_page,
            per_page: self.per_page,
            teacher_id: self.filterForm.teacher_id,
            course_category_id: self.filterForm.course_category_id,
            keyword: self.filterForm.keyword,
          })
          .then((response) => {
            if (response.status == 200) {
              self.tableData = response.data.data;
              self.total = response.data.meta.total;
              self.current_page = response.data.meta.current_page;
              self.last_page = response.data.meta.last_page;
            } else {
              console.log(response.message);
            }

            self.loading = false;
            if (JSON.parse(sessionStorage.getItem("curTags"))) {
              self.pageTags = JSON.parse(sessionStorage.getItem("curTags"));
            }
          })
          .catch((err) => {
            console.log("××××××××××××××");
            console.log(err);
            self.loading = false;
          });
      },
      handleSizeChange(val) {
        self.per_page = val;
        self.getList();
      },
      handleCurrentChange(val) {
        self.current_page = val;
        self.getList();
      },
      toSearch() {
        self.current_page = 1;
        self.getList();
      },
      toClear() {
        self.filterForm = {
          teacher_id: "",
          course_category_id: "",
          keyword: "",
        };
        self.toSearch();
      },
      getTeachersList() {
        self
          .$fetch("admin/teacher")
          .then((response) => {
            if (response.status == 200) {
              self.teachersData = response.data.data;
            } else {
              console.log(response.message);
            }
          })
          .catch((err) => {
            console.log("××××××××××××××");
            console.log(err);
          });
      },
      getBigCategory() {
        self
          .$fetch("admin/course/category", {
            course_category_id: 0,
          })
          .then((response) => {
            if (response.status == 200) {
              self.bigCategoryData = response.data.data;
            } else {
              console.log(response.message);
            }
          })
          .catch((err) => {
            console.log("××××××××××××××");
            console.log(err);
          });
      },
      bigCateChange(e) {
        self.subCategoryData = [];
        self.ruleForm.sub_category_id = "";

        self
          .$fetch("admin/course/category", {
            course_category_id: e,
          })
          .then((response) => {
            if (response.status == 200) {
              self.subCategoryData = response.data.data;
            } else {
              console.log(response.message);
            }
          })
          .catch((err) => {
            console.log("××××××××××××××");
            console.log(err);
          });
      },
      getCourseType() {
        self
          .$fetch("admin/course/type")
          .then((response) => {
            if (response.status == 200) {
              self.courseTypeData = response.data.data;
            } else {
              console.log(response.message);
            }
          })
          .catch((err) => {
            console.log("××××××××××××××");
            console.log(err);
          });
      },
      getTermsList() {
        self
          .$fetch("admin/term")
          .then((response) => {
            if (response.status == 200) {
              self.termsData = response.data.data;
            } else {
              console.log(response.message);
            }
          })
          .catch((err) => {
            console.log("××××××××××××××");
            console.log(err);
          });
      },
      add() {
        self.addDialog = true;
        self.dialogTitle = "قوشۇش";
        self.dialogVisible = true;
      },
      handleEdit(index, row) {
        self.addDialog = false;
        self.dialogTitle = "تەھرىرلەش";
        self.editId = row.id;
        self.ruleForm.image = row.image;
        self.ruleForm.big_category_id = row.course_category_id;
        // self.ruleForm.expired_at = row.expired_at;
        self.ruleForm.teacher_id = row.teacher_id;
        self.ruleForm.name_ug = row.name_ug;
        self.ruleForm.name_zh = row.name_zh;
        self.ruleForm.description_ug = row.description_ug;
        self.ruleForm.description_zh = row.description_zh;
        self.ruleForm.order = row.order;
        self.ruleForm.price = row.price;
        if (row.prices.length > 0) {
          var tmpArr = [];
          row.prices.forEach(function(one, index) {
            var tmpObj = {};
            tmpObj.id = one.id;
            tmpObj.term_id = one.term_id;
            tmpObj.price = one.price;
            tmpArr.push(tmpObj);
          });
          self.termPriceList = tmpArr;
        }
        self.ruleForm.recommend = row.recommend + "";
        self.ruleForm.status = row.status + "";
        self.dialogVisible = true;
      },
      handleDelete(index, row) {
        self
          .$confirm("ئۆچۈرۈشنى مۇقۇملامسىز ؟", "ئەسكەرتىش", {
            confirmButtonText: "مۇقىملاش",
            cancelButtonText: "بىكار قىلىش",
            type: "warning",
          })
          .then(() => {
            self
              .$remove("admin/course/" + row.id)
              .then((response) => {
                console.log(response);
                if (response.status == 204) {
                  self.getList();
                  self.$message({
                    message: "ئۆچۈرۈش تاماملاندى",
                    type: "success",
                    duration: 1200,
                  });
                } else {
                  console.log(response);
                }
              })
              .catch((err) => {
                console.log("××××××××××××××××");
                console.log(err);
              });
          })
          .catch(() => {
            self.$message({
              type: "info",
              message: "ئۆچۈرۈش بىكار قىلىندى",
            });
          });
      },
      handleImageSuccess(res, file) {
        self.ruleForm.image = res.data.image;
      },
      handleImageError(err, file) {
        var uploadErr = JSON.parse(err.toString().replace("Error:", ""));
        self.$message({
          message: uploadErr.message,
          type: "warning",
        });
      },
      submitForm(formName) {
        var myData = {
          image: self.ruleForm.image,
          // expired_at: self.ruleForm.expired_at,
          teacher_id: self.ruleForm.teacher_id,
          name_ug: self.ruleForm.name_ug,
          name_zh: self.ruleForm.name_zh,
          description_ug: self.ruleForm.description_ug,
          description_zh: self.ruleForm.description_zh,
          order: self.ruleForm.order,
          price: self.ruleForm.price,
          prices: self.termPriceList,
          recommend: self.ruleForm.recommend,
          status: self.ruleForm.status,
        };
        if (self.ruleForm.sub_category_id) {
          myData.course_category_id = self.ruleForm.sub_category_id;
        } else {
          myData.course_category_id = self.ruleForm.big_category_id;
        }

        if (self.addDialog) {
          self
            .$post("admin/course", myData)
            .then((response) => {
              if (response.status == 201) {
                self.closeDialog(formName);
                self.getList();
                self.$message({
                  message: response.data.message,
                  type: "success",
                  duration: 1200,
                });
              } else {
                console.log(response.data.message);
              }
            })
            .catch((err) => {
              console.log("××××××××××××××");
              console.log(err);
            });
        } else {
          self
            .$put("admin/course/" + self.editId, myData)
            .then((response) => {
              if (response.status == 201) {
                self.closeDialog(formName);
                self.getList();
                self.$message({
                  message: response.data.message,
                  type: "success",
                  duration: 1200,
                });
              } else {
                self.$message({
                  message: response.data.message,
                  type: "warning",
                  duration: 1500,
                });
              }
            })
            .catch((err) => {
              console.log("××××××××××××××");
              console.log(err);
            });
        }
      },
      resetForm(formName) {
        self.$refs[formName].resetFields();
        self.ruleForm = {
          image: "",
          big_category_id: "",
          sub_category_id: "",
          // expired_at:"",
          teacher_id: "",
          name_ug: "",
          name_zh: "",
          description_ug: "",
          description_zh: "",
          order: "",
          price: "",
          recommend: "1",
          status: "1",
        };
        self.termPriceList = [
          {
            id: null,
            term_id: "",
            price: "",
          },
        ];
      },
      closeDialog(formName) {
        self.resetForm(formName);
        self.dialogVisible = false;
      },
      handleClose(done) {
        done();
        self.resetForm("ruleForm");
      },
      handleContent(row) {
        self.curCourse = row;
        self.showList = false;
        self.showContent = true;

        self.contentLoading = true;
        self
          .$fetch("admin/course/content", {
            course_id: self.curCourse.id,
          })
          .then((response) => {
            if (response.status == 200) {
              self.contentTableData = response.data.data;
            } else {
              console.log(response.message);
            }
            self.contentLoading = false;
          })
          .catch((err) => {
            console.log("××××××××××××××");
            console.log(err);
            self.contentLoading = false;
          });
      },
      handleComment(row) {
        self.curCourse = row;
        self.showList = false;
        self.showComment = true;

        self.commentLoading = true;
        self
          .$fetch("admin/course/comment", {
            course_id: self.curCourse.id,
          })
          .then((response) => {
            if (response.status == 200) {
              self.commentTableData = response.data.data;
            } else {
              console.log(response.message);
            }
            self.commentLoading = false;
          })
          .catch((err) => {
            console.log("××××××××××××××");
            console.log(err);
            self.commentLoading = false;
          });
      },
      handleDeleteComment(index, row) {
        self
          .$confirm("ئۆچۈرۈشنى مۇقۇملامسىز ؟", "ئەسكەرتىش", {
            confirmButtonText: "مۇقىملاش",
            cancelButtonText: "بىكار قىلىش",
            type: "warning",
          })
          .then(() => {
            self
              .$remove("admin/course/comment/" + row.id)
              .then((response) => {
                console.log(response);
                if (response.status == 204) {
                  self.handleComment(self.curCourse);
                  self.$message({
                    message: "ئۆچۈرۈش تاماملاندى",
                    type: "success",
                    duration: 1200,
                  });
                } else {
                  console.log(response);
                }
              })
              .catch((err) => {
                console.log("××××××××××××××××");
                console.log(err);
              });
          })
          .catch(() => {
            self.$message({
              type: "info",
              message: "ئۆچۈرۈش بىكار قىلىندى",
            });
          });
      },
      handleEditComment(index, row) {
        self.curCommentId = row.id;
        self.commentStatus = row.status + "";
        self.dialogVisibleEditComment = true;
      },
      submitCommentForm() {
        self
          .$put("admin/course/comment/" + self.curCommentId, {
            status: self.commentStatus,
          })
          .then((response) => {
            if (response.status == 201) {
              self.dialogVisibleEditComment = false;
              self.handleComment(self.curCourse);
              self.$message({
                message: response.data.message,
                type: "success",
                duration: 1200,
              });
            } else {
              self.$message({
                message: response.data.message,
                type: "warning",
                duration: 1500,
              });
            }
          })
          .catch((err) => {
            console.log("××××××××××××××");
            console.log(err);
          });
      },
      addContent() {
        self.dialogTitleContent = "مۇندەرىجە قوشۇش";
        self.ruleFormContent.order = self.contentTableData.length + 1; //排序字段每次添加完一个就自增1
        self.addContentDialog = true;
        self.dialogVisibleContent = true;
      },
      handleSelectionChange(val) {
        self.multipleSelection = val;
        console.log(self.multipleSelection);
      },
      setContentFree() {
        if (self.multipleSelection.length == 0) {
          self.$message({
            message: "ئاز بولغاندا بىر تال مۇندەرىجە تاللاڭ",
            type: "warning",
            duration: 1200,
          });
        } else {
          self.multiChangeVisible = true;
        }
      },
      submitMultiChange() {
        var ids = [];
        self.multipleSelection.forEach(function(item, index) {
          ids.push(item.id);
        });
        self
          .$put("admin/course/content/free", {
            ids: ids,
            free: self.multiFree,
          })
          .then((response) => {
            if (response.status == 201) {
              self.handleContent(self.curCourse);
              self.$message({
                message: response.data.message,
                type: "success",
                duration: 1200,
              });
              self.multiChangeVisible = false;
            } else {
              self.$message({
                message: response.data.message,
                type: "warning",
                duration: 1500,
              });
            }
          })
          .catch((err) => {
            console.log("××××××××××××××");
            console.log(err);
          });
      },
      handleEditContent(index, row) {
        self.dialogTitleContent = "مۇندەرىجە تەھرىرلەش";
        self.addContentDialog = false;
        self.curContentId = row.id;
        self.ruleFormContent.course_id = row.course_id;
        self.ruleFormContent.course_type_id = row.courseType.id;
        self.ruleFormContent.name_ug = row.name_ug;
        self.ruleFormContent.name_zh = row.name_zh;
        self.ruleFormContent.content_ug = row.content_ug;
        self.ruleFormContent.content_zh = row.content_zh;
        self.ruleFormContent.source_url = row.source_url;
        self.ruleFormContent.order = row.order;
        self.ruleFormContent.free = row.free + "";
        self.ruleFormContent.status = row.status + "";
        self.dialogVisibleContent = true;
      },
      handleDeleteContent(index, row) {
        self
          .$confirm("ئۆچۈرۈشنى مۇقۇملامسىز ؟", "ئەسكەرتىش", {
            confirmButtonText: "مۇقىملاش",
            cancelButtonText: "بىكار قىلىش",
            type: "warning",
          })
          .then(() => {
            self
              .$remove("admin/course/content/" + row.id)
              .then((response) => {
                console.log(response);
                if (response.status == 204) {
                  self.handleContent(self.curCourse);
                  self.$message({
                    message: "ئۆچۈرۈش تاماملاندى",
                    type: "success",
                    duration: 1200,
                  });
                } else {
                  console.log(response);
                }
              })
              .catch((err) => {
                console.log("××××××××××××××××");
                console.log(err);
              });
          })
          .catch(() => {
            self.$message({
              type: "info",
              message: "ئۆچۈرۈش بىكار قىلىندى",
            });
          });
      },
      handleCloseContent(done) {
        done();
        self.resetFormContent("ruleFormContent");
      },
      resetFormContent(formName) {
        // self.$refs[formName].resetFields();
        self.ruleFormContent = {
          course_id: "",
          course_type_id: self.ruleFormContent.course_type_id,
          name_ug: "",
          name_zh: "",
          content_ug: "",
          content_zh: "",
          source_url: "",
          order: self.ruleFormContent.order,
          free: "0",
          status: "1",
        };
      },
      closeDialogContent(formName) {
        self.resetFormContent(formName);
        self.dialogVisibleContent = false;
      },
      submitFormConetnt(formName, isClose) {
        var tmpData = {
          course_id: self.curCourse.id,
          course_type_id: self.ruleFormContent.course_type_id,
          name_ug: self.ruleFormContent.name_ug,
          name_zh: self.ruleFormContent.name_zh,
          content_ug: self.ruleFormContent.content_ug,
          content_zh: self.ruleFormContent.content_zh,
          source_url: self.ruleFormContent.source_url,
          order: self.ruleFormContent.order,
          free: self.ruleFormContent.free,
          status: self.ruleFormContent.status,
        };
        console.log(tmpData);

        if (self.addContentDialog) {
          self
            .$post("admin/course/content", tmpData)
            .then((response) => {
              if (response.status == 201) {
                if (isClose == "close") {
                  self.closeDialogContent(formName);
                } else {
                  self.resetFormContent(formName);
                  setTimeout(function() {
                    self.ruleFormContent.order = self.contentTableData.length + 1; //排序字段每次添加完一个就自增1
                  }, 1000);
                }
                self.handleContent(self.curCourse);
                self.$message({
                  message: response.data.message,
                  type: "success",
                  duration: 1200,
                });
              } else {
                console.log(response.data.message);
              }
            })
            .catch((err) => {
              console.log("××××××××××××××");
              console.log(err);
            });
        } else {
          self
            .$put("admin/course/content/" + self.curContentId, tmpData)
            .then((response) => {
              if (response.status == 201) {
                if (isClose == "close") {
                  self.closeDialogContent(formName);
                } else {
                  self.resetFormContent(formName);
                }
                self.handleContent(self.curCourse);
                self.$message({
                  message: response.data.message,
                  type: "success",
                  duration: 1200,
                });
              } else {
                self.$message({
                  message: response.data.message,
                  type: "warning",
                  duration: 1500,
                });
              }
            })
            .catch((err) => {
              console.log("××××××××××××××");
              console.log(err);
            });
        }
      },
      addItem(arr) {
        if (arr.length < 5) {
          var tmpData = {
            term_id: "",
            price: "",
          };
          arr.push(tmpData);
        } else {
          self.$message({
            message: "بەك كۆپ بوپكەتتىمۇ قانداق؟",
            type: "warning",
          });
        }
      },
      removeItem(arr, index) {
        arr.splice(index, 1);
      },
      backToList() {
        self.showList = true;
        self.showContent = false;
        self.showComment = false;
        self.curCourse = null;
      },
    },
    deactivated() {
      self.backToList();
    },
    components: {
      VueUeditorWrap,
    },
  };
</script>
